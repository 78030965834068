import axios from "axios";
import { isEmpty } from "jvh-is-empty";
import env from '../constants/envs';
let service;
if (process.env.NODE_ENV === 'production') {
  service = "https://covert-pointer-svc-j4n5cd7baq-uc.a.run.app"
} else {
  service = 'http://localhost:8080'
}

// const service = "localhost:8080"
const createRoom = async (roomName) => {
  const config = {
    url: `${service}/api/room/create`,
    method: 'POST',
    headers: {
      "covert-pointer-key": env.COVERT_API_KEY
    },
    data: roomName
  };

  const { data } = await axios(config);
  return data;
};

const addUser = async (userName, room_id) => {

  const config = {
    url: `${service}/api/room/add_user/${room_id}`,
    method: 'PUT',
    headers: {
      "covert-pointer-key": env.COVERT_API_KEY
    },
    data: {
      name: userName
    }
  };

  const { data } = await axios(config);
  return data;
};

const setUserPoint = async (room_id, user_id, point) => {
  const config = {
    url: `${service}/api/room/update_room/${room_id}/${user_id}`,
    method: 'PUT',
    headers: {
      "covert-pointer-key": env.COVERT_API_KEY
    },
    data: {
      point_selection: point
    }
  };

  const { data } = await axios(config);
  return data;
};

const updateRoom = async (room_id, formData) => {
  const config = {
    url: `${service}/api/room/update_room/${room_id}`,
    method: 'PUT',
    headers: {
      "covert-pointer-key": env.COVERT_API_KEY
    },
    data: formData
  };

  const { data } = await axios(config);
  return data;
};

const removeContents = async (room_id, user_id) => {
  const config = {
    url: `${service}/api/room/remove_contents/${room_id}${!isEmpty(user_id) ? `/${user_id}` : ''}`,
    method: 'DELETE',
    headers: {
      "covert-pointer-key": env.COVERT_API_KEY
    },
  };

  const { data } = await axios(config);
  return data;
};

const wipeVotes = async (room_id, userList) => {
  const config = {
    url: `${service}/api/room/wipe_votes/${room_id}`,
    method: 'PUT',
    headers: {
      "covert-pointer-key": env.COVERT_API_KEY
    },
    data: userList
  };

  const { data } = await axios(config);
  return data;
};

const exportedFuncs = {
  createRoom,
  addUser,
  setUserPoint,
  updateRoom,
  removeContents,
  wipeVotes
};

export default exportedFuncs;
