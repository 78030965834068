import React from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'jvh-is-empty';

// shared
import roomAPI from '../../../shared/api/room';
import colors from '../../../shared/constants/colors';

const ClearVotesButton = ({ users}) => {
  const { room_id } = useParams();
  const clearVotes = useMutation((userList) => roomAPI.wipeVotes(room_id, { userList: [...userList] }), {
    onError: (error) => {
      console.log(error);
    },
  });

  const styles = {
    btn: {
      border: `1px solid ${colors.accent}`,
      borderRadius: '3px',
      height: '2.2rem',
      fontSize: '1rem',
      fontWeight: '500',
      margin: '1rem',
      padding: '.4rem .6rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer',
      outline: 'none',
      backgroundColor: 'transparent',
      minWidth: '6rem',
      color: colors.accent
    }
  };

  return <button style={styles.btn} onClick={() => !isEmpty(users) && clearVotes.mutate(Object.keys(users)) }>Clear Votes</button>
};

export default ClearVotesButton;