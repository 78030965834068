import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'jvh-is-empty';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Button from '../../Layout/Button/Button';

// shared
import points from '../../../shared/constants/points';
import colors from '../../../shared/constants/colors';
import UserContext from '../../../shared/context/UserContext';
import roomAPI from '../../../shared/api/room';

const PointSelection = ({ users }) => {
  const { room_id } = useParams();
  const storage_user_id = localStorage.getItem('user_id');
  const storage_user_name = localStorage.getItem('user_name');
  const [stateUserId, setStateUserId] = useState(null);
  const { user } = useContext(UserContext);

  const addUserPoint = useMutation((param) => roomAPI.setUserPoint(room_id, param.stateUserId, param.point), {
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (isEmpty(users)) {
      return;
    }
    if ((isEmpty(storage_user_id) || isEmpty(storage_user_name)) && !isEmpty(user.id)) {
      setStateUserId(user.id);
    } else {
      setStateUserId(storage_user_id);
    }
  }, [storage_user_id, storage_user_name, users, user]);

  const styles = {
    button: {
      border: `1px solid ${colors.borderColor}`,
      margin: '.5rem .5rem',
      height: '4.5rem',
      width: '3.2rem',
      backgroundColor: 'transparent',
      color: colors.accent,
      fontWeight: '600',
      cursor: 'pointer',
      minWidth: '3rem'
    }
  };

  return (
    <Wrapper>
      {points.map(({ point }, i) => <Button key={i} style={styles.button} onClick={() => {
        if (!isEmpty(stateUserId)) {
          addUserPoint.mutate({point, stateUserId});
        }
      }}>{point}</Button>)}
    </Wrapper>
  );
};

export default PointSelection;