import React from 'react';

// components
import Wrapper from '../components/Layout/Wrapper/Wrapper';
import Nav from '../components/Layout/Nav/Nav';

// assets
import notfound404 from '../assets/notfound404.png';

// constants
import colors from '../shared/constants/colors';

const NotFound = ({ message }) => {
  const styles = {
    notFoundCard: {
      position: 'absolute',
      left: '50%',
      top: '48%',
      transform: 'translate(-50%, -50%)',
      WebkitTransform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '40rem',
      borderRadius: '6px',
      padding: '1rem',
      backgroundColor: colors.blueAccent
    },
    notFoundTitle: {
      margin: 0,
      padding: 0,
      fontSize: '2rem'
    },
    img: {
      width: '100%',
      maxWidth: '20rem'
    }
  };

  return (
    <>
    <Nav />
    <div style={styles.notFoundCard}>
      <Wrapper>
        <h2>{message}</h2>
      </Wrapper>
      <Wrapper>
        <img style={styles.img} src={notfound404} alt="404 not found" />
      </Wrapper>
    </div>
    </>
  )
};

export default NotFound;