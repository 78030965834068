import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader'

// shared
import colors from '../../../shared/constants/colors';

const Spinner = () => {
  return (
    <PulseLoader
      color={colors.blueAccent}
      size={10}
      speedMultiplier={.8}
      margin={2}
    />
  )
};

export default Spinner;