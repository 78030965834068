import React from 'react';
import { FaMugSaucer, FaRegCopyright } from "react-icons/fa6";

// constants 
import colors from '../../../shared/constants/colors';

const Footer = () => {
  const styles = {
    footer: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: 0,
    },
    copyrightText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.textColor,
      fontSize: '.8rem',
      padding: 0,
      margin: 0
    },
    ownershipText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.textColor,
      fontSize: '.8rem',
      padding: 0,
      margin: '.5rem 0 .5rem 0'
    },
    copyIcon: {
      marginRight: '.5rem',
      color: colors.blueAccent
    },
    mugIcon: {
      margin: '0 .5rem 0 .5rem',
      color: colors.blueAccent
    },
  };

  return (
    <footer style={styles.footer}>
      <p style={styles.copyrightText}><FaRegCopyright style={styles.copyIcon} /> 2023 - {new Date().getFullYear()}</p>
      <p style={styles.ownershipText}>Build with <FaMugSaucer style={styles.mugIcon} /> by Jeffrey VanHorn</p>
    </footer>
  )
};
export default Footer;