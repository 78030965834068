import React, { useState } from 'react';
import { useMutation } from "react-query";
import { useNavigate } from 'react-router-dom';

// components
import Button from '../../Layout/Button/Button';

// api
import roomAPI from '../../../shared/api/room';

// constants 
import colors from '../../../shared/constants/colors';

const CreateRoom = () => {
  const [roomName, setRoomName] = useState("");
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const createRoomService = useMutation((roomName) => roomAPI.createRoom({ room_name: roomName }), {
    onSuccess: (data) => {
      setRoomName("");
      if (data.room_id) {
        navigate(`/room/${data.room_id}`);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    createRoomService.mutate(roomName);
  };

  const styles = {
    form: {
      border: `1px solid ${colors.borderColor}`,
      borderRadius: '3px',
      padding: '1rem',
      margin: '1rem',
      display: 'flex',
      justifyContent: 'center',
      flexFlow: 'column wrap',
      alignContent: 'center',
      alignItems: 'center',
      width: '100%',
      maxWidth: '30rem',
    },
    roomInput: {
      width: '100%',
      padding: '.5rem',
      fontSize: '1rem',
      outline: 'none',
      borderRadius: '3px',
      border: '1px solid white',
    },
    createRoomBtn: {
      border: `1px solid ${colors.accent}`,
      borderRadius: '3px',
      height: '2.2rem',
      fontSize: '1rem',
      margin: '1rem',
      padding: '.4rem .6rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer',
      outline: 'none',
      backgroundColor: 'transparent',
      minWidth: '6rem',
      color: colors.accent
    }
  };

  return (
    <form style={styles.form}>
      <input
        value={roomName}
        onChange={(e) => setRoomName(e.target.value)}
        type="text"
        placeholder="Room Name"
        style={styles.roomInput}
      />
      <Button style={styles.createRoomBtn} loading={createRoomService.isLoading} onClick={onSubmit} disabled={!roomName}>Create Room</Button>
    </form>
  );
};

export default CreateRoom;