import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ref, onValue } from "firebase/database";
import { db } from "../shared/utils/firebase";
import { isEmpty } from 'jvh-is-empty';

// components
import AddUserModal from '../components/Room/AddUserModal/AddUserModal';
import RoomData from '../components/Room/RoomData/RoomData';
import PageLoader from '../components/Layout/PageLoader/PageLoader';
import Nav from '../components/Layout/Nav/Nav';

// shared
import UserContext from '../shared/context/UserContext';

// containers 
import NotFound from './NotFound';

const Room = () => {
  const { room_id } = useParams();
  const [room, setRoom] = useState();
  const [renderModal, setRenderModal] = useState(false);
  const [fetchingRoom, setFetchingRoom] = useState(true);
  const [user, setUser] = useState({
    id: null,
    name: null,
    roomId: null
  });

  useEffect(() => {
    setFetchingRoom(true);
    if (room_id) {
      const query = ref(db, `rooms/${room_id}`);

      return onValue(query, (snapshot) => {
        const data = snapshot.val();
        if (snapshot.exists) {
          setRoom(data);
          setFetchingRoom(false);
        }
      });
    }
  }, []);

  const needsLocalStorage = () => {
    const localStorageRoom = localStorage.getItem("room_id");
    const localStorageUser = localStorage.getItem("user_name");
    const localStorageUserId = localStorage.getItem("user_id");

    // if there are no users... just add them immediately
    if (isEmpty(room.users)) {
      setRenderModal(true);
      return;
    }

    // if any local storage items are empty. assign it to the new room
    if (isEmpty(localStorageRoom) || isEmpty(localStorageUser) || isEmpty(localStorageUserId)) {
      setRenderModal(true);
      return;
    }

    // if the user was in a different room before, add them to the new room
    if (JSON.stringify(room.room_id) !== localStorageRoom) {
      setRenderModal(true);
      return;
    }

    // if the local user id is not in the room, add them to the room
    if (!JSON.stringify(room.users).includes(localStorageUserId)) {
      setRenderModal(true);
      return;
    }

    setRenderModal(false);
  };

  useEffect(() => {
    if (!isEmpty(room)) {
      needsLocalStorage();
    }
  }, [room]);

  return isEmpty(room) && fetchingRoom ? <PageLoader /> : (
    <UserContext.Provider value={{ user, setUser }}>
      {isEmpty(room) && !fetchingRoom ?
        <NotFound message="The room you're looking for does not exist!" />
        :
        <div>
          <Nav />
          {<RoomData {...room} />}
          {renderModal && <AddUserModal isOpen={renderModal} room_id={room.room_id} modalText="Join Session" />}
        </div>
      }
    </UserContext.Provider>
  );
};

export default Room;