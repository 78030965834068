import React, { useEffect } from "react";

// components
import Wrapper from "../components/Layout/Wrapper/Wrapper";
import CreateRoom from "../components/Room/CreateRoom/CreateRoom";
import Footer from "../components/Layout/Footer/Footer";

// constants 
import colors from '../shared/constants/colors';

const Home = () => {
  const styles = {
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
      textAlign: "center",
      color: colors.textColor
    }
  }
  return (
    <div>
      <Wrapper>
        <h1 style={styles.title}>Covert Pointer</h1>
      </Wrapper>
      <Wrapper>
        <CreateRoom />
      </Wrapper>
      <Footer />
    </div>
  );
};

export default Home;