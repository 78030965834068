let envObj = {};
if (process.env.NODE_ENV === 'production') {
  envObj = {
    FIREBASE_API_KEY: "AIzaSyAWi1_K0WnQKjYOftXJLlR5iWdEHmPAlpU",
    FIREBASE_AUTH_DOMAIN: "portfolio-365115.firebaseapp.com",
    FIREBASE_DATABASE_URL: "https://portfolio-365115-default-rtdb.firebaseio.com",
    FIREBASE_PROJECT_ID: "portfolio-365115",
    FIREBASE_STORAGE_BUCKET: "portfolio-365115.appspot.com",
    FIREBASE_MESSAGING_SENDER_ID: "651655626539",
    FIREBASE_APP_ID: "1:651655626539:web:a0f3c76762fe1d27418f6e",
    COVERT_API_KEY: "?,517TSY5B#+$3T~gk",
  };
} else {
  envObj = {
    FIREBASE_API_KEY: "AIzaSyCcANPPUJVZytrecl1UdsPLiHQ3MYQwHwo",
    FIREBASE_AUTH_DOMAIN: "covert-pointer.firebaseapp.com",
    FIREBASE_DATABASE_URL: "https://covert-pointer-default-rtdb.firebaseio.com/",
    FIREBASE_PROJECT_ID: "covert-pointer",
    FIREBASE_STORAGE_BUCKET: "covert-pointer.appspot.com",
    FIREBASE_MESSAGING_SENDER_ID: "864283906619",
    FIREBASE_APP_ID: "1:864283906619:web:35f343a1994d32d009021e",
    COVERT_API_KEY: "?,517TSY5B#+$3T~gk",
  };
}

export default envObj;