import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import { QueryClient, QueryClientProvider } from "react-query";

// components
import Home from "./containers/Home";
import Room from "./containers/Room";
import NotFound from './containers/NotFound';

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/room/:room_id" element={<Room />} />
          <Route path="*" element={<NotFound message="The page you're looking for does not exist!" />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;