import React, { useContext } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';

// constants
import colors from '../../../shared/constants/colors';

// api
import roomAPI from '../../../shared/api/room';

const ShowHideButtons = ({ users, isShowing }) => {
  const { room_id } = useParams();
  const updateRoom = useMutation((data) => roomAPI.updateRoom(room_id, data), {
    onError: (error) => {
      console.log(error);
    },
  });

  const styles = {
    btn: {
      border: `1px solid ${colors.accent}`,
      borderRadius: '3px',
      height: '2.2rem',
      fontSize: '1rem',
      fontWeight: '500',
      margin: '1rem',
      padding: '.4rem .6rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer',
      outline: 'none',
      backgroundColor: 'transparent',
      minWidth: '6rem',
      color: colors.accent,
    }
  };

  return (
    <Wrapper>
      <button style={{ ...styles.btn, backgroundColor: isShowing ? colors.dark : 'transparent' }} onClick={() => { updateRoom.mutate({ show_points: true }) }}>Show</button>
      <button style={{ ...styles.btn, backgroundColor: !isShowing ? colors.dark : 'transparent' }} onClick={() => { updateRoom.mutate({ show_points: false }) }}>Hide</button>
    </Wrapper>

  );
};

export default ShowHideButtons;