import React, { useState, useContext } from 'react';
import { isEmpty } from 'jvh-is-empty';
import { useMutation } from "react-query";
import { useNavigate } from 'react-router-dom';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Button from '../../Layout/Button/Button';

// shared
import colors from '../../../shared/constants/colors';
import roomAPI from '../../../shared/api/room';
import UserContext from '../../../shared/context/UserContext';

const AddUserModal = ({ isOpen, modalText, room_id }) => {
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [userName, setUserName] = useState();
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const closeModal = () => setModalOpen(false);

  const addUserService = useMutation((userName) => roomAPI.addUser(userName, room_id), {
    onSuccess: (data) => {
      localStorage.setItem("room_id", room_id);
      localStorage.setItem("user_name", userName);
      localStorage.setItem("user_id", data.userId);
      setUser({ id: data.userId, name: userName, roomId: room_id})
      closeModal();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const modalActionHandler = (e) => {
    e.preventDefault();
    if (isEmpty(userName)) return;
    addUserService.mutate(userName);
  };

  const styles = {
    modalWindow: {
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      zIndex: '999',
      WebkitTransition: 'all 0.3s',
      MozTransition: 'all 0.3s',
      OTransition: 'all 0.3s',
      transition: 'all 0.3s',
      visibility: modalOpen ? 'visible' : 'hidden',
      opacity: modalOpen ? 1 : 0,
      pointerEvents: modalOpen ? 'auto' : 'none'
    },
    modalContainer: {
      width: '90%',
      maxWidth: '30rem',
      position: 'absolute',
      top: '50%',
      left: '50%',
      padding: '1rem',
      WebkitTransform: 'translate(-50%, -50%)',
      MozTransform: 'translate(-50%, -50%)',
      OTransform: 'translate(-50%, -50%)',
      transform: 'translate(-50%, -50%)',
      background: '#212121',
      borderRadius: '8px',
      overflow: 'hidden',
    },
    modalText: {
      textAlign: 'center',
      margin: '1rem',
      fontWeight: '700',
      fontSize: '1.4rem',
      color: colors.textColor,
    },
    input: {
      width: '100%',
      padding: '.5rem',
      fontSize: '1rem',
      outline: 'none',
      borderRadius: '3px',
      border: '1px solid white',
    },
    joinRoomBtn: {
      border: `1px solid ${colors.blueAccent}`,
      backgroundColor: 'transparent',
      color: colors.blueAccent
    },
    cancelBtn: {
      border: `1px solid ${colors.accent}`,
      backgroundColor: 'transparent',
      color: colors.accent
    },
  };

  return (
    <div
      style={styles.modalWindow}
      id="open-modal"
      className="modal-window"
    >
      <div style={styles.modalContainer}>
        <div style={styles.modalText} id="modalContent">
          {!isEmpty(modalText) && modalText}
        </div>
        <form onSubmit={modalActionHandler}>
          <input style={styles.input} value={userName} type="text" onChange={(e) => setUserName(e.target.value)} placeholder="Your Name" />
          <Wrapper styles={{ justifyContent: 'space-evenly' }}>
            <Button style={styles.cancelBtn} type="button" onClick={() => {
              closeModal();
              navigate('/');
            }}>Cancel</Button>
            <Button style={styles.joinRoomBtn} type="submit" loading={addUserService.isLoading}>Join</Button>
          </Wrapper>
        </form>
      </div>
    </div>
  );
};

export default AddUserModal;