import React from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader'


// shared
import colors from '../../../shared/constants/colors';

const PageLoader = () => {
  const styles = {
    wrapper: {  
      position: 'fixed',
      top: '50%',
      left: '50%',
      WebkitTransform: 'translate(-50%, -50%)',
      MozTransform: 'translate(-50%, -50%)',
      OTransform: 'translate(-50%, -50%)',
      transform: 'translate(-50%, -50%)',
    },
  };
  return (
    <div style={styles.wrapper}>
      <PropagateLoader
        color={colors.blueAccent}
        size={16}
        speedMultiplier={1.1}
      />
    </div>
  )
};

export default PageLoader;