const pointList = [
  {
    point: 0,
  },
  {
    point: 1,
  },
  {
    point: 3,
  },
  {
    point: 5,
  },
  {
    point: 8
  },
  {
    point: 13
  },
  {
    point: 20
  },
  {
    point: 40
  },
  {
    point: 100
  }
];
export default pointList;