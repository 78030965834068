import React, { useState } from 'react';
import { FaCopy } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { isEmpty } from 'jvh-is-empty';

// shared
import colors from '../../../shared/constants/colors';

// components
import Alert from '../../Layout/Alert/Alert';

const CopyRoom = () => {
  const { room_id } = useParams();
  const [alertConfig, setAlertConfig] = useState({
    type: '',
    message: '',
    show: false,
  });

  const renderAlert = () => {
    if (!alertConfig.show) {   
      setAlertConfig({
        type: 'success',
        message: 'Link copied to clipboard!',
        show: true,
      });
    }
  };

  const hideAlert = () => {
    setTimeout(() => {
      setAlertConfig({
        type: '',
        message: '',
        show: false,
      });
    }, 5000);
  };

  const onCopy = () => {
    if (!isEmpty(room_id)) {
      navigator.clipboard.writeText(window.location.href)
      renderAlert();
      hideAlert();
    }
  };

  const styles = {
    wrap: {
      margin: '2rem 0 1rem 0',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
    },
    icon: {
      color: colors.blueAccent,
      margin: '0 .4rem 0 0',
      fontSize: '.9rem'
    },
    text: {
      color: colors.textColor,
      fontSize: '.9rem'
    }
  };

  return (
    <div style={styles.wrap} onClick={onCopy}>
      <Alert {...alertConfig} />
      <FaCopy style={styles.icon} />
      <span style={styles.text}>Invite a friend!</span>
    </div>
  );
};

export default CopyRoom;