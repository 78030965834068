import React, { useState } from 'react';
import { isEmpty } from 'jvh-is-empty';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { FaCheckCircle } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";

//components 
import Divider from '../../Layout/Divider/Divider';

// shared
import colors from '../../../shared/constants/colors';
import profilePic from '../../../assets/profilebg.png';
import roomAPI from '../../../shared/api/room';

const UserData = ({ user, userId, showPoints }) => {
  const user_id = localStorage.getItem("user_id");
  const { room_id } = useParams();
  const [hoveringProfile, setHoveringProfile] = useState(false);

  const removeUser = useMutation(() => roomAPI.removeContents(room_id, userId), {
    onError: (error) => {
      console.log(error);
    },
  });

  const showPoint = () => {
    if (user_id === userId) {
      return true;
    }

    if (showPoints) {
      return true;
    }

    return false;
  };

  const styles = {
    rowWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '.8rem .4rem'
    },
    point: {
      color: colors.blueAccent,
      width: '3rem',
    },
    rightDisplay: {
      backgroundColor: !showPoint() ? colors.blueAccent : 'transparent',
      minWidth: '1.8rem',
      textAlign: 'center',
      borderRadius: '3px'
    },
    deleteIcon: {
      cursor: 'pointer',
      fontSize: '4rem',
      position: 'absolute',
      left: '50%',
      top: '48%',
      transform: 'translate(-50%, -50%)',
      WebkitTransform: 'translate(-50%, -50%)',
      WebkitTransition: 'all .3s ease-in-out',
      MozTransition: 'all .3s ease-in-out',
      MsTransition: 'all .3s ease-in-out',
      OTransition: 'all .3s ease-in-out',
      transition: 'all .3s ease-in-out',
      opacity: hoveringProfile ? 1 : 0,
      color: colors.accent,
    },
    profileStatWrap: {
      width: '100%',
      maxWidth: '2.6rem',
      position: 'relative',
      cursor: 'pointer',
      
    },
    profileImg: {
      width: '100%',
      opacity: hoveringProfile ? 0 : 1,
      WebkitTransition: 'all .3s ease-in-out',
      MozTransition: 'all .3s ease-in-out',
      MsTransition: 'all .3s ease-in-out',
      OTransition: 'all .3s ease-in-out',
      transition: 'all .3s ease-in-out',
    },
    checkIcon: {
      position: 'absolute',
      bottom: 0,
      right: '.1rem',
      color: colors.blueAccent,
      fontSize: '1.2rem',
      border: 'none',
      opacity: user.point_selection != null && !hoveringProfile ? 1 : 0,
      transition: '.3s ease-in-out',
      WebkitTransition: '.3s ease-in-out',
      MozTransition: '.3s ease-in-out',
      MsTransition: '.3s ease-in-out',
      OTransition: '.3s ease-in-out',
    },
    userName: {
      color: colors.textColor,
      margin: '0 .6rem',
      padding: 0
    },
    leftDisplay: {
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <>
      <div style={styles.rowWrap}>
        <div style={styles.leftDisplay}>
          <div
            style={styles.profileStatWrap}
            onMouseEnter={() => setHoveringProfile(true)}
            onMouseLeave={() => setHoveringProfile(false)}
            onClick={() => removeUser.mutate()}>
            <img style={styles.profileImg} src={profilePic} />
            <FaCheckCircle style={styles.checkIcon} />
            <TiDelete style={styles.deleteIcon} />
          </div>
          <p style={styles.userName}>{user.name}</p>
        </div>
        <div style={styles.rightDisplay}>
          <span style={styles.point}>{!isEmpty(user.point_selection) && user.point_selection}</span>
        </div>
      </div>
      <Divider />
    </>
  )
};

export default UserData;