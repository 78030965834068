import React, { useEffect, useState } from 'react';
import { isEmpty } from 'jvh-is-empty';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import ShowHideButtons from '../ShowHideButtons/ShowHideButtons';
import CopyRoom from '../CopyRoom/CopyRoom';

// constants
import colors from '../../../shared/constants/colors';

const Statistics = ({ users, showPoints }) => {

  const [average, setAverage] = useState(null);
  const [pointAllocation, setPointAllocation] = useState({});

  useEffect(() => {
    if (showPoints && !isEmpty(users)) {
      // gets the number of users that have voted
      const votedUsers = Object.entries(users).filter(([_, value]) => {
        if (!value.point_selection && value.point_selection !== 0) {
          return false;
        }
        return true;
      }).map(([key, _]) => key);

      if (votedUsers.length > 0) {
        // sums up total point count
        const totalPointCount = Object.values(users).map(({ point_selection }) => point_selection).reduce((a, b) => {
          if (a == null || b == null) {
            return a || b;
          }
          return a + b
        });
        setAverage(parseFloat(totalPointCount / votedUsers.length).toFixed(2));
      } else {
        setAverage(null);
      }

      let assignedAllocations = {};
      Object.entries(users).filter(([_, value]) => {
        if (!value.point_selection && value.point_selection !== 0) {
          return false;
        }
        return true;
      }).map(([_, user]) => {
        const userPoint = user.point_selection;
        if (assignedAllocations[userPoint]) {
          assignedAllocations[userPoint] = assignedAllocations[userPoint] + 1;
        } else {
          assignedAllocations[userPoint] = 1;
        }
        // this may cause issues
        return null;
      });
      setPointAllocation(assignedAllocations);
    } else {
      setPointAllocation({});
      setAverage(null);
    }
  }, [showPoints, users]);

  const styles = {
    statCard: {
      width: '100%',
      maxWidth: '20rem',
      border: `1px solid ${colors.borderColor}`,
      borderRadius: '3px',
      margin: '1rem',
      minHeight: '16rem',
      padding: '1rem'
    },
    statCardTitle: {
      color: colors.textColor
    },
    average: {
      color: colors.textColor
    },
    statTableWrap: {

    },
    statTable: {
      width: '100%'
    },
    thead: {
      textAlign: 'left',
      color: colors.textColor,
      borderBottom: `1px solid ${colors.blueAccent}`,
      fontWeight: '700',
      fontSize: '1.2rem',
      padding: '.5rem 0 .5rem .5rem'
    },
    tbody: {
      color: colors.textColor,
    },
    tdata: {
      padding: '.5rem',
      borderBottom: `1px solid ${colors.borderColor}`,
    }
  };

  return (
    <div style={styles.statCard}>
      <Wrapper>
        <h1 style={styles.statCardTitle}>Statistics</h1>
      </Wrapper>
      <ShowHideButtons users={users} isShowing={showPoints} />

      {showPoints && average != null && <Wrapper><p style={styles.average}>Avg: {average}</p></Wrapper>}
      {showPoints && !isEmpty(pointAllocation) &&
        <Wrapper>
          <table style={styles.statTable}>
            <thead>
              <tr>
                <th style={styles.thead}>Points</th>
                <th style={styles.thead}>Votes</th>
              </tr>
            </thead>
            <tbody style={styles.tbody}>
              {Object.entries(pointAllocation).map(([key, value], i) => <tr style={styles.trow} key={i}><td style={styles.tdata}>{key}</td><td style={styles.tdata}>{value}</td></tr>)}
            </tbody>
          </table>
        </Wrapper>
      }
      <Wrapper>
        <CopyRoom />
      </Wrapper>
    </div>
  );
};

export default Statistics;