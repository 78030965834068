import React from 'react';
import { isEmpty } from 'jvh-is-empty';
import Moment from 'react-moment';
import { FaCalendarAlt } from "react-icons/fa";

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import UserData from './UserData';
import PointSelection from '../PointSelection/PointSelection';
import Statistics from '../Statistics/Statistics';
import ClearVotesButton from '../ClearVotesButton/ClearVotesButton';
import Divider from '../../Layout/Divider/Divider';

// shared
import colors from '../../../shared/constants/colors';

const RoomData = ({ date_created, room_name, users, show_points }) => {

  const styles = {
    card: {
      border: `1px solid ${colors.borderColor}`,
      borderRadius: '3px',
      padding: '1rem',
      margin: '1rem',
      width: '100%',
      maxWidth: '30rem',
      display: 'flex',
      justifyContent: 'center',
      flexFlow: 'column wrap',
      alignContent: 'center',
      alignItems: 'center'
    },
    roomTitle: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: colors.textColor,
      margin: 0,
      padding: 0
    },
    createdP: {
      color: colors.textColor,
      fontSize: '.9rem',
      margin: '.5rem 0',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    calendarIcon: {
      marginRight: '.5rem',
      color: colors.blueAccent
    },
    playerBox: {
      width: '100%'
    },
    playerBoxTitle: {
      color: colors.textColor,
      fontWeight: '700',
      fontSize: '1.2rem',
      margin: '1rem 0'
    },
    playerDataWrap: {

    },
  };

  return (
    <Wrapper styles={{ alignItems: 'flex-start' }}>
      <div style={styles.card}>
        <h1 style={styles.roomTitle}>{room_name}</h1>
        <p style={styles.createdP}><FaCalendarAlt style={styles.calendarIcon} /> <Moment format="MM/DD/YYYY">{date_created}</Moment></p>
        <ClearVotesButton users={users} />
        <Divider style={{ margin: '1rem 0 1.6rem 0' }} />
        <PointSelection users={users} />
        <div style={styles.playerBox}>
          <Wrapper styles={{ justifyContent: 'flex-start' }}>
            <h4 style={styles.playerBoxTitle}>Players:</h4>
          </Wrapper>
          <div style={styles.playerDataWrap}>
            <Divider />
            {!isEmpty(users) && Object.entries(users).map(([key, value], i) => <UserData key={i} userId={key} showPoints={show_points} user={value} />)}
          </div>
        </div>
      </div>
      <Statistics users={users} showPoints={show_points} />
    </Wrapper>
  );
};

export default RoomData;