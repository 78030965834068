import React from 'react';
import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";


// styles
import './alert.css';

// components
import Wrapper from '../Wrapper/Wrapper';

// shared
import colors from '../../../shared/constants/colors';

const Alert = ({
    type,
    message,
    show,
}) => {
    const returnHeaderColorName = (alertType, valueReturn) => {
        switch (alertType) {
            case 'success':
                return <FaCheckCircle style={{ ...styles.alertIcon }} />
            case 'error':
                return <MdError style={{ ...styles.alertIcon }} />
            default: return null;
        }
    };

    const styles = {
        alertRoot: {
            overflow: 'hidden',
        },
        alertComponent: {
            display: show ? '' : 'none',
            position: 'fixed',
            borderRadius: '4px',
            top: '1rem',
            right: '1rem',
            width: '95%',
            maxWidth: '13rem',
            backgroundColor: colors.borderColor,
            zIndex: 9999999,
            overflow: 'hidden',
            WebkitAnimationDuration: '4s',
            WebkitAnimationIterationCount: 1,
            WebkitAnimationFillMode: 'both',
            WebkitAnimationName: 'alert-in',
            MozAnimationDuration: '4s',
            MozAnimationIterationCount: 1,
            MozAnimationFillMode: 'both',
            MozAnimationName: 'alert-in',
            MsAnimationDuration: '4s',
            MsAnimationIterationCount: 1,
            MsAnimationFillMode: 'both',
            MsAnimationName: 'alert-in',
            animationDuration: '4s',
            animationIterationCount: 1,
            animationFillMode: 'both',
            animationName: 'alert-in'
        },
        alertHead: {
            backgroundColor: colors.blueAccent,
            padding: '.2rem',
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px'
        },
        typeName: {
            margin: 0,
            padding: 0,
            color: 'black',
            fontSize: '1rem'
        },
        alertIcon: {
            fontSize: '1.5rem',
            color: 'black'
        },
        alertBody: {
            height: '100%',
            minHeight: '3rem',
            padding: '.3rem .5rem',
            display: 'flex',
            alginItems: 'center',
            justifyContent: 'flex-start',
        },
        alertMsg: {
            fontSize: '.8rem',
            fontWeight: 600,
            margin: 0,
            padding: 0,
            width: '100%',
            textAlign: 'left'
        },
        alertFooter: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        closeIcon: {
            color: colors.blueAccent,
            fontSize: '1.3rem',
            margin: '.2rem .5rem'
        },
    };

    return (
        <div id="alertStyles_root" style={styles.alertRoot}>
            <div className="large-rad large-shadow alertComponent" style={styles.alertComponent}>
                <div style={styles.alertHead}>
                    <Wrapper styles={{ justifyContent: 'space-between' }}>
                        <h6 style={styles.typeName}>
                            {
                                returnHeaderColorName(type, 'name')
                            }
                        </h6>
                    </Wrapper>
                </div>
                <Wrapper styles={{ ...styles.alertBody }}>
                    <p style={styles.alertMsg}>
                        {message}
                    </p>
                </Wrapper>
            </div>
        </div>
    );
};

export default Alert;