import React from 'react';

// components
import Spinner from '../Spinner/Spinner';

const Button = ({ disabled, type, style, onClick, loading, children }) => {
  const buttonStyles = {
    border: '1px solid white',
    borderRadius: '3px',
    height: '2.2rem',
    fontSize: '1rem',
    fontWeight: '600',
    margin: '1rem',
    padding: '.4rem .6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
    minWidth: '6rem',
    color: 'black',
  };

  return <button
    className="small-shadow"
    disabled={disabled}
    type={type}
    style={{ ...buttonStyles, ...style }}
    onClick={onClick}>{!loading ? children : <Spinner />}
  </button>;
};


export default Button;