import React from 'react';

// constants
import colors from '../../../shared/constants/colors';

const Divider = ({ style }) => {
  const styles = {
    border: `.1rem solid ${colors.borderColor}` ,
    width: '100%',
    ...style
  };

  return <div style={styles} />
};

export default Divider;