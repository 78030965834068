import React from 'react';
import UndercoverImg from '../../../assets/undercover.jpeg';
import { useNavigate } from 'react-router-dom';

// shared
import colors from '../../../shared/constants/colors';

// components
import Wrapper from '../Wrapper/Wrapper';

const Nav = () => {
  const navigate = useNavigate();

  const styles = {
    nav: {
      width: '100%',
      height: '3.5rem',
      backgroundColor: colors.accent,
      margin: '0 0 2rem 0',
    },
    logo: {
      width: '100%',
      maxWidth: '3rem',
      margin: '0 .5rem 0 .5rem'
    },
    link: {
      cursor: 'pointer',
      margin: '0 1rem 0 1rem',
      fontSize: '1.2rem',
      fontWeight: '600'
    }
  };

  return (
    <nav style={styles.nav}>
      <Wrapper styles={{ justifyContent: 'space-between' }}>
        <img alt="Logo" style={styles.logo} src={UndercoverImg} />
        <span style={styles.link} onClick={() => {
          navigate('/')
        }}>
          Home
        </span>
      </Wrapper>
    </nav>
  );
};

export default Nav;